<script>
import Layout from "@/views/layouts/static/main";
import PageHeader from "@/components/static/page-header";
import appConfig from "@/app.config";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import listPlugin from "@fullcalendar/list";
// import DateRangePicker from "vue2-daterange-picker";
// import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";
// import sidebar from "./components/sidebar.vue";
import employeeViewCalendar from "./components/employeeViewCalendar.vue";
import vacantEmployeeViewCalendar from "./components/vacantEmployeeView.vue";
import assignShiftModal from "@/views/pages/admin/shift/components/assignShiftSlot.vue";
import AlertWidget from "@/components/general/AlertWidget.vue";
import CreateForm from "@/views/pages/admin/shift/components/createForm.vue";
/**
 * Add-product component
 */

const shift_Prototype = { 
    shift_type:'Non_Premium',
    label_color:'#0a0a0a',
    bonus:null,
    name:null,
    interval: null,
    chunk: null,
    from: null,
    to: null,
    desc: null,
    location_id:null,
  };

const roles_Prototype = [{
    id:null,
    count:null,
    role_count:null,
    remaining_slots:null,
  }];
export default {
  page: {
    title: "Schedule",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    FullCalendar,
    Layout,
    PageHeader,
    CreateForm,
    // DateRangePicker,
    // sidebar,
    employeeViewCalendar,
    vacantEmployeeViewCalendar,
    assignShiftModal,
    AlertWidget,
  },

  data() {
    return {
      isClick: false,
      calenderRef: {},
      showCalander: false,
      title: "Shifts Schedule",
      items: [
        {
          text: "Schedule",
        },
        {
          text: "Calendar",
          active: true,
        },
      ],
      currentTabComponent: null,
      tabs: [],
      calendarOptions: {
        headerToolbar: {
          left: "prev,title,next",
          // center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        },
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin,
          bootstrapPlugin,
          listPlugin,
        ],
        initialView: "dayGridMonth",
        themeSystem: "bootstrap",
        events: [],
        editable: true,
        droppable: true,
        eventResizableFromStart: true,
        dateClick: this.dateClicked,
        eventClick: this.editEvent,
        eventsSet: this.handleEvents,
        weekends: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        customButtons: {
          prev: {
            text: "PREV",
            click: () => {
              this.fetchPrevData();
            },
          },
          next: {
            // this overrides the next button
            text: "Next",
            click: () => {
              this.fetchNextData();
            },
          },
        },
      },
      currentEvents: [],
      showModal: false,
      submitted: false,
      submit: false,
      newEventData: {},
      edit: {},
      deleteId: {},
      renderCalendar: true,
      location: "showAll",
      shifts: [],
      shift_names: ["Breakfast", "Lunch", "Dinner"],
      table_data: { name: null, pagination: 0 },

      // vue Date Picker
      date_range: {
        startDate: null,
        endDate: null,
      },
      area_role: {},
      shift_timeline_copy: [],
      vacant_time_slots: [],
      loading: false,
      viewAs: "calendar",
      sideBarData: {},
      assign_shift : {},
      currentLocation : {},
      currentLocationText : new Date(),
      vacantEmployeeName:null,

      location_id:null,
      createShiftModal : false,
      shiftTimeBreaks:[],
      timebreakshiftfrom: null,
      timebreakshiftto: null, 
      processing : false,
      clickedDate : null,
      shiftTo: null,
      shiftFrom : null,
      interval : null,
      roles: JSON.parse(JSON.stringify(roles_Prototype)),
      shift: JSON.parse(JSON.stringify(shift_Prototype)),
    };
  },

  filters: {
    date(val) {
      return val ? moment(val).format("YYYY-MM-DD") : "";
    },
  },

  watch: {
    "calenderRef.view": {
      handler: function (newValue) {
        if (newValue?.type == "timeGridDay") {
          this.$refs.sideBar.showCalander = true;
        } else {
          this.$refs.sideBar.showCalander = false;
        }
      },
      deep: true,
    },

    'shift.from'(newVal){
      if(!this.isInvalid(newVal)){
        this.shiftFrom = this.clickedDate+'T'+this.shift.from;
        const format1 = "YYYY-MM-DD HH:mm:ss";
        this.interval=this.getDayname(this.shiftFrom);
        this.timebreakshiftfrom = moment(this.shiftFrom).format(format1);
        this.shift.chunk =null;
        this.shift.chunk =1;
      }
     
    },

    'shift.to'(newVal){
      if(!this.isInvalid(newVal)){
        this.shiftTo = this.clickedDate+'T'+this.shift.to;
        if( this.shiftTo <= this.shiftFrom){
          this.triggerSwal("shift to time must be greater then from time", "error");
        }
        const format1 = "YYYY-MM-DD HH:mm:ss";
        this.timebreakshiftto = moment(this.shiftTo).format(format1);
        this.shift.chunk =0;
        this.shift.chunk =1;
      }
    },

    'shift.chunk' :{
      handler : function(newVal){
        if(this.shiftFrom && this.shiftTo && newVal != null)
        {
          this.shiftTimeBreaks = [];
          let intervalDiff=this.hoursTimeDiff(this.timebreakshiftfrom,this.timebreakshiftto)
          let ratio= this.determineRatio(intervalDiff,this.shift.chunk);
          let timeBreak=this.numbertoHours(ratio);
          if(this.shift.chunk>1){
              this.addHoursTime(this.timebreakshiftfrom,timeBreak,this.shift.chunk);
          }else{
            this.shiftTimeBreaks.push({from:this.changeDateTimeToUTCZero(this.shiftFrom),to:this.changeDateTimeToUTCZero(this.shiftTo),type:'Full'});
          }
        }
      },deep : true,
    }

    // "date_range.endDate": {
    //   handler: function (new_data, old_data) {
    //     if (new_data) {
    //       this.table_data.from = this.formatDateYMD(this.date_range.startDate);
    //       this.table_data.to = this.formatDateYMD(new_data);
    //       this.fetchShift();
    //     }
    //   },
    // },
  },

  mounted() {
    this.fetchShift();
  },

  methods: {
    uploadShift() {
      let self = this;
      if(this.$refs.createForm.chunkError === true) {
        self.triggerSwal("Chunks Cannot be greater than 3", "error");
        return;
      }
      this.$refs.createForm.$v.$touch();
      if(this.$refs.createForm.$v.$invalid) {
        self.triggerSwal("Fields Cannot be empty", "error");
        return;
      }
      if(self.roles.length <= 0){
        self.triggerSwal("Role is required", "error");
        return;
      }
      this.roles.forEach(role => {
          if(role.id == null) {
              self.triggerSwal("Role is not Selected", "error");
              return;
          } else if(role.count <= 0) {
              self.triggerSwal("There Should be Atleast 1 Role Count", "error");
              return;
          }
      });

      if (self.processing) {
        return;
      }
      self.processing = true;
      
      self.$axios.post('/shift/store', {
          name: self.shift.name,
          // desc:self.shift.desc,
          from:self.shiftFrom,
          to: self.shiftTo,
          interval: self.interval,
          shift_type:self.shift.shift_type,
          chunk:self.shift.chunk,
          bonus:self.shift.bonus,
          location_id:self.shift.location_id,
          label_color:self.shift.label_color,
          roles: self.roles,
          // shifttimebreak:this.shiftTimeBreaks,
          //utc_from/to is to save time in shift area roles.
          utc_from:self.changeDateTimeToUTCZero(self.shiftFrom),
          utc_to: self.changeDateTimeToUTCZero(self.shiftTo),
      })
      .then(response => {
        let data = response.data;
        self.triggerSwal(data.message, "success");
        this.fetchShift();
        this.hideCreateShiftModal();
      })
      .catch(error => {
          self.handleErrorResponse(error.response, "error");
      })
      .finally(function () {
          self.processing = false;
      });
    },

    hoursTimeDiff(startTime,endTime){
      var todayDate=this.getCurrentDate();  //  return in format YYYY-MM-DD 
      var startDate = new Date(`${startTime}`);
      var endDate = new Date(`${endTime}`);
      var timeDiff = Math.abs(startDate.getTime() - endDate.getTime());
      var hh = Math.floor(timeDiff / 1000 / 60 / 60);   
      hh = ('0' + hh).slice(-2)
      timeDiff -= hh * 1000 * 60 * 60;
      var mm = Math.floor(timeDiff / 1000 / 60);
      mm = ('0' + mm).slice(-2)
      timeDiff -= mm * 1000 * 60;
      var ss = Math.floor(timeDiff / 1000);
      ss = ('0' + ss).slice(-2)
      return {hh:hh , mm:mm , ss:ss};
     },

     determineRatio(intervalDiff, duration) {
      let totalHours=intervalDiff.hh+ ' : ' + intervalDiff.mm+ ' : ' + intervalDiff.ss;
      var totalHoursArray = totalHours.split(':');
      var numerator = parseInt(totalHoursArray[0])*3600 
        + parseInt(totalHoursArray[1])*60 
        + parseInt(totalHoursArray[2]);
      var res=numerator/duration;
      return res/3600
    },

    numbertoHours(value){
      var hours = Math.floor(value);
      var minutes = Math.round((value % 1)*100)/100;
      return {hours:hours, minutes:minutes};       
    },

    addHoursTime(startTime,breakAbleTime,chunks){
      this.shiftTimeBreaks=[];
      let from=startTime;
      const format1 = "YYYY-MM-DD HH:mm:ss";
      var todayDate=this.getCurrentDate();  //  return in format YYYY-MM-DD 
      for(let i=1;i<=chunks;i++){
          var dt = new Date(`${from}`);
          dt.setHours(dt.getHours() + breakAbleTime.hours);
          dt.setMinutes(dt.getMinutes() + breakAbleTime.minutes);
          let to =moment(dt).format(format1);
          this.shiftTimeBreaks.push({from:this.changeDateTimeToUTCZero(from),to:this.changeDateTimeToUTCZero(to),type:'Half'});
          from=to;
      }
    },

    hideCreateShiftModal(){
      this.$refs.createForm.$v.$reset();
      this.clickedDate = null;
      this.shift = JSON.parse(JSON.stringify(shift_Prototype));
      this.roles = JSON.parse(JSON.stringify(roles_Prototype));
      this.shiftFrom = null;
      this.shiftTo = null;
      this.createShiftModal = false;
    },

    fetchShift() {
      this.loading = true;
      this.$axios
        .get("/shift/index", { params: this.table_data })
        .then((response) => {
          let data = response.data.payload.paginated;
          this.shifts = data;
          this.calendarOptions.events = [];
          this.buildEvents(this.shifts);
          this.loading = false;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    buildEvents(shifts) {
      this.calendarOptions.events = shifts.map((shift) => {
        let forPremium = "";
        let forVacantShifts = "";
        if (shift.shift_type == "Premium") {
          forPremium = " (" + shift.shift_type + ") *";
        }
        if(shift?.shift_roles)
        {
          shift.shift_roles.find((timeslot) => {
            if(timeslot.pivot.remaining_slots > 0)
            {
              return forVacantShifts = "Vacant Shift - ";
            }
          });
        }

        if (this.calenderRef?.view?.type == "timeGridDay") {
          this.calenderRef?.changeView(
            "timeGridDay",
            this.changeDateTimeToLocalFormats(shift.shift_roles[0].pivot.from)
          );
        }
        if (this.calenderRef?.view?.type == "timeGridWeek") {
          this.calenderRef?.changeView(
            "timeGridWeek",
            this.changeDateTimeToLocalFormats(shift.shift_roles[0].pivot.from)
          );
        }
        return {
          id: shift.id,
          title: forVacantShifts + shift.name + " " + forPremium,
          start: this.changeFormatTimeCalendarBanner(this.changeTimeZone(shift.shift_roles[0].pivot.from)),
          end: this.changeFormatTimeCalendarBanner(this.changeTimeZone(shift.shift_roles[0].pivot.to)),
          className: "primaryColorBg text-white",
          backgroundColor: shift.label_color,
          shift: shift,
        };
      });
    },

    getRoleName(role_id) {
      let roleName = null;
      this.edit.extendedProps.shift.area_roles.map((role) => {
        if (role_id == role.id) {
          roleName = role.name;
        }
      });
      return roleName;
    },
    hideModal(e) {
      this.submitted = false;
      this.showModal = false;
    },

    /**
     * Modal open for add event
     */
    dateClicked(info) {
      this.newEventData = info;
      this.clickedDate = info.dateStr;
      this.createShiftModal = true;
      // this.showModal = true;
    },
    /**
     * Modal open for edit event
     */
    editEvent(info) {
      this.edit = info.event;
      this.shift_timeline_copy = this.edit.extendedProps.shift.shift_timeline;
      if(this.edit.extendedProps.shift.shift_roles.length > 0)
      {
        this.fetchSlots(this.edit.extendedProps.shift.id);
      } else {
        this.vacant_time_slots = [];
      }

      this.showModal = true;

      this.adjustCalendarSize();
    },

    adjustCalendarSize() {
      setTimeout(() => {
        this.calenderRef.updateSize();
      });
    },

    closeModal() {
      this.showModal = false;
      this.adjustCalendarSize();
    },

    /**
     * Show list of events
     */
    handleEvents(events) {
      this.currentEvents = events;
    },

    submitActions(status, shift) {
      if (shift.status == "Apply For Leave") {
        if (status == "Rejected") {
          status = "Leave Rejected";
        } else {
          status = "Released";
        }
      }
      let self = this;
      self.$axios
        .post("shift/employee/shift-action/" + shift.id, {
          status: status,
          employee_id: shift.employee_id,
          area_role_id: shift.area_role_id,
          shift_id: shift.shift_id,
          slot_id: shift.slot_id,
          action_detail: shift.action_detail,
        })
        .then((response) => {
          let data = response.data;
          this.showModal = false;
          self.triggerSwal(data.message, "success");

          this.shifts.map((shift) => {
            shift.shift_timeline.map((timeline) => {
              if (timeline.id == data.payload.id) {
                timeline.status = data.payload.status;
              }
            });
          });
          this.buildEvents(this.shifts);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(function () {});
    },
    fetchSlots(shift_id) {
      this.$axios
        .get("shift-roles", {
          params: {
            shift_id: shift_id,
            empty_slot: true,
          },
        })
        .then((response) => {
          this.vacant_time_slots = response.data.payload;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    filterByViewAs() {
      this.viewAs = this.$refs.sideBar.viewAs;
    },

    listenFromSideBar(newData) {
      if(newData?.vacantEmployeeName){
         this.vacantEmployeeName = newData?.vacantEmployeeName;
      }
      if (newData?.shift_name) {
        if (newData?.shift_name == "All") {
          this.table_data.name = null;
        }else{
          this.table_data.name = newData?.shift_name;
        }
      }

      if (newData?.viewAs) {
        this.viewAs = newData?.viewAs;
      }

      if (newData?.location) {
        if(newData?.location == "showAll"){
          this.currentLocation = {};
          this.table_data.location_id = null;
          this.currentLocationText = new Date();
        }else{
        this.table_data.location_id = newData.location.id;
        this.currentLocation = newData.location.time_zone;
        this.currentLocationText = newData?.location?.time_zone?.text;
        }
      }

      if (newData?.area) {
        this.table_data.area_id = newData?.area?.id;
      }

      // if (newData?.endDate) {
      //   this.table_data.from = newData.startDate;
      //   this.table_data.to = newData.endDate;
      // }

      if (newData?.employee) {
        this.table_data.employee_id = newData?.employee?.employee?.id;
      }

      if (newData?.role) {
        this.table_data.area_role_id = newData?.role?.id;
      }

      if (newData?.availability) {
        if (newData?.availability == "All") {
          this.table_data.availability = null;
        }else{
          this.table_data.availability = newData.availability;
        }
      }

      if (newData?.shift_status) {
        if (newData?.shift_status == "All") {
          this.table_data.shift_status = null;
        }else{
          this.table_data.shift_status = newData.shift_status;
        }
      }

      if (newData?.clearFilters) {

        this.table_data = {
          name: null,
          pagination: 0,
        };
        this.currentLocationText = new Date();
        this.viewAs = "calendar";
        let view = this.calenderRef.view;
        this.table_data.from = this.formatDateYMD(view.currentStart);
        this.table_data.to = this.formatDateYMD(view.currentEnd);
      }

      if (newData != null) {
        this.fetchShift();
      }
    },

    setCalender() {
      this.calenderRef = this.$refs.fullCalendar?.getApi();
      let view = this.calenderRef.view;
      this.table_data.from = this.formatDateYMD(view.currentStart);
      this.table_data.to = this.formatDateYMD(view.currentEnd);
      this.fetchShift();
    },

    fetchNextData() {
      this.calenderRef.next();
      let view = this.calenderRef.view;
      this.table_data.from = this.formatDateYMD(view.currentStart);
      this.table_data.to = this.formatDateYMD(view.currentEnd);
      this.fetchShift();
    },
    fetchPrevData() {
      this.calenderRef.prev();
      let view = this.calenderRef.view;

      this.table_data.from = this.formatDateYMD(view.currentStart);
      this.table_data.to = this.formatDateYMD(view.currentEnd);
      this.fetchShift();
    },

    changeTimeZone(date)
    {
      if(Object.entries(this.currentLocation).length > 0){
        let zone = this.currentLocation.utc[0];
        return this.changeDateTimeTimeZone(date,zone);
      }else{
        return this.DateWithMonthName(date , 'DD-MM-YYYY hh:mm A');
      }
    },
    searchVacantEmployee(searchItem){
 this.vacantEmployeeName = searchItem;
    }
  },

  middleware: "authentication",
};
</script>

<template>
  <Layout :dynamicClass="'container-fluid'">
    <div class="row">
      <!-- <div
        class="col-xl-2 ps-0 position-relative transition_class"
        :class="{ fullWidth: isClick }"
      >
        <sidebar ref="sideBar" @filterData="listenFromSideBar" @searchVacantEmployee="searchVacantEmployee" />
        <i
          class="
            fas
            position-absolute
            top-0
            end-0
            px-4
            f-16
            primaryColor
            pointer
          "
          @click="isClick = !isClick"
          :class="isClick == false ? 'fa-times' : 'fa-bars'"
        >
        </i>
      </div> -->
      <div class="col-xl-12" :class="{ 'col-xl-12': isClick }">
        <PageHeader :title="title" :items="items" />

        <APILoader
          v-if="loading"
          :loading="loading"
          class="align-middle"
        ></APILoader>
        <div>
        <h6 class="text-end text-pink me-3">Timezone : {{currentLocationText}}</h6>
          <div class="card" v-if="viewAs == 'calendar'">
            <div class="card-body under-tabs">
              <div class="row">
                <div
                  :class="{
                    'col-xxl-8 col-xl-8 col-lg-8': showModal == true,
                    'col-xxl-12 col-xl-12 col-lg-12': showModal == false,
                  }"
                >
                  <div class="tab-content" id="adminCalendar">
                    <FullCalendar
                      class="vue-calendar-ui"
                      v-if="renderCalendar"
                      ref="fullCalendar"
                      :options="calendarOptions"
                      @hook:mounted="setCalender"
                    ></FullCalendar>
                  </div>
                </div>
                <div v-if="showModal" class="col-xxl-4 col-xl-4 col-lg-4">
                  <div v-if="edit.extendedProps != null" class="mt-md-0 mt-0">
                    <div class="cross-ico text-end mb-2">
                      <button
                        @click="closeModal()"
                        class="btn py-1 px-2 btn-outline-pink mx-3"
                      >
                        <i class="fas fa-arrow-right"></i>
                      </button>
                    </div>
                    <div
                      class="
                        d-flex
                        justify-content-between
                        gap-2
                        align-items-center
                        shadow
                        bg-dark-pink
                        radius-10
                        px-3
                        py-2
                        shadow
                      "
                    >
                      <div class="shiftname">
                        <h3 class="f-18 mb-0 pb-0 text-white">
                          {{ edit.extendedProps.shift.name }}
                        </h3>
                      </div>
                      <!-- <div class="shift-role">
                        <select
                          @change="filterByRole()"
                          v-model="area_role"
                          class="
                            form-select form-select-sm
                            border-2 border-white
                            ps-3
                            pe-5
                            rounded-pill
                            f-12
                          "
                          aria-label="Default select example"
                        >
                          <option
                            class="text-dark"
                            :value="area_role"
                            v-for="(area_role, role_index) in edit.extendedProps
                              .shift.area_roles"
                            :key="role_index"
                          >
                            {{  getSafe(()=> area_role.name ) }}
                          </option>
                          <option
                            class="text-dark"
                            :value="area_role"  v-if="edit.extendedProps.shift.area_roles.length == 0" disabled selected
                          >
                            Unassigned Role
                          </option>
                        </select>
                      </div> -->
                    </div>
                    <div>
                      <div class="my-3 calendar-tabs">
                        <b-tabs content-class="mt-3" fill>
                          <b-tab title="Vacant" class="text-dark" active>
                            <div
                              class="position-relative"
                              v-for="(timeslot, index) in vacant_time_slots"
                              :key="index"
                            >
                              <div
                                class="
                                  user-box
                                  d-flex
                                  justify-content-between
                                  align-items-center
                                  py-3
                                  px-3
                                  rounded
                                "
                              >
                                <div class="user-info d-flex gap-3">
                                  <div>
                                    <i class="fa fa-arrow-right"></i>
                                  </div>
                                  <div class="user-name w-100">
                                    <p class="pb-0 mb-0 f-12 text-muted">
                                      Time:
                                      {{  getSafe(()=> changeTimeZone(timeslot.from) ) }} -
                                      {{  getSafe(()=> changeTimeZone(timeslot.to) ) }}
                                    </p>
                                    <div class="d-flex my-2">
                                      <p class="f-12 w-50 my-0 mx-0">
                                        Remaining Count :
                                        {{  getSafe(()=> timeslot.remaining_slots ) }}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="d-flex mb-2 justify-content-end">
                                <button
                                class="btn btn-pink btn-sm rounded"
                                v-b-modal.modal-assignShift
                                @click="
                                  assign_shift =
                                    edit.extendedProps.shift
                                "
                                >
                                  Assign Shift
                                </button>
                              </div>
                              <div class="cl-hr-line"></div>
                            </div>
                          </b-tab>

                          <b-tab title="Schedule">
                            <div
                              v-if="
                                edit.extendedProps.shift.shift_timeline.length >
                                0
                              "
                            >
                              <div
                                class="position-relative"
                                v-for="(timeline, index) in edit.extendedProps
                                  .shift.shift_timeline"
                                :key="index"
                              >
                                <div
                                  class="
                                    user-box
                                    d-flex
                                    justify-content-between
                                    align-items-center
                                    py-3
                                    px-3
                                    rounded
                                  "
                                >
                                  <div class="user-info d-flex gap-3">
                                    <div class="user-img w-25">
                                      <img
                                        :src="
                                          timeline.employee.user.avatar_path
                                        "
                                        class="user-img-cl"
                                        alt="user image"
                                      />
                                    </div>
                                    <div class="user-name w-100">
                                      <h3 class="f-14 mb-0 pb-0 poppins">
                                        {{  getSafe(()=> timeline.employee.user.name ) }}
                                      </h3>
                                      <p class="pb-0 mb-0 f-12 text-muted">
                                        <!-- {{ currentLocation }} -->
                                        {{ getSafe(()=> timeline.from )}} - 
                                        {{  getSafe(()=> timeline.to ) }}<span class="ms-2">{{
                                          timeline.status
                                        }}</span>
                                      </p>
                                      <p class="pb-0 mb-0 f-12 text-muted">
                                        {{ getSafe(()=>  getRoleName(timeline.area_role_id) )}}
                                      </p>
                                    </div>
                                  </div>

                                  <div class="shift-menu">
                                    <b-dropdown
                                      v-if="
                                        timeline.status != 'Accepted' &&
                                        timeline.status != 'Released' &&
                                        timeline.status != 'Rejected'
                                      "
                                      id="dropdown-right"
                                      right
                                      html="<i class='fas fa-ellipsis-v'></i>"
                                      variant="white"
                                    >
                                      <b-dropdown-item
                                        class="f-12"
                                        @click="
                                          submitActions('Accepted', timeline)
                                        "
                                        ><i class="fas fa-check-square me-2"></i
                                        >Accept</b-dropdown-item
                                      >
                                      <b-dropdown-item
                                        class="f-12"
                                        @click="
                                          submitActions('Rejected', timeline)
                                        "
                                        ><i
                                          class="fas fa-times-circle me-2"
                                        ></i>
                                        Reject</b-dropdown-item
                                      >
                                    </b-dropdown>
                                    <div class="text-center text-muted" v-else>
                                      Approved
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="cl-hr-line"></div>
                            </div>
                            <AlertWidget
                              text="No shift scheduled"
                              v-else
                            ></AlertWidget>
                          </b-tab>
                        </b-tabs>
                      </div>
                    </div>

                    <!-- <div class="bg-light radius-10">
                      <div
                        class="
                          d-flex
                          gap-2
                          align-items-center
                          justify-content-between
                          px-3
                          py-3
                        "
                      >
                        <div class="shift-time">
                          <p class="f-12 mb-0">
                            <span class="d-block fw-semibold text-dark"
                              >From</span
                            >
                            <span class="d-block text-muted">{{
                              changeTimeZone(edit.extendedProps.shift.shift_roles[0].from)
                            }}</span>
                          </p>
                        </div>
                        <div class="shift-date">
                          <p class="f-12 mb-0">
                            <span class="d-block fw-semibold text-dark"
                              >To</span
                            >
                            <span class="d-block text-muted">{{
                              changeTimeZone(edit.extendedProps.shift.shift_roles[0].to)
                            }}</span>
                          </p>
                        </div>
                      </div>

                      <div class="px-3 pb-3">
                        <div class="d-flex justify-content-between">
                          <div class="interval">
                            <span class="f-12 fw-bold d-block"
                              >Required Count of {{ edit.extendedProps.shift.shift_roles[0].name }}:</span
                            >
                            <span class="f-12 d-block text-muted">{{ getSafe(()=>
                              edit.extendedProps.shift.shift_roles[0].pivot.role_count
                            )}}</span>
                          </div>
                          <div class="type-of-shift">
                            <span
                              class="badge bg-timecolor rounded-0 text-dark"
                              >{{ edit.extendedProps.shift.shift_type }}</span
                            >
                          </div>
                        </div>
                      </div>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <employeeViewCalendar
            :area_id="table_data.area_id"
            :name="table_data.name"
            :employee_id="table_data.employee_id"
            :area_role_id="table_data.area_role_id"
            :availability="table_data.availability"
            v-else-if="viewAs == 'employee'"
          />
          <vacantEmployeeViewCalendar
            :area_id="table_data.area_id"
            :name="table_data.name"
            :employee_id="table_data.employee_id"
            :area_role_id="table_data.area_role_id"
            :availability="table_data.availability"
            :searchAbleDataProp="vacantEmployeeName"
            v-else-if="viewAs == 'vacant_employee'"
          />
          <b-modal
            v-model="createShiftModal"
            size="lg"
            title="Create Shift"
            title-class="text-black font-18"
            body-class="p-3"
            hide-footer
            no-close-on-backdrop no-close-on-esc hide-header-close 
          >
            <create-form :roles="roles" :location_id="location_id" :shift="shift" ref="createForm" :shiftFromCalendar="true"></create-form>
            <SubmitButton text="Create Shift" @clicked="uploadShift" :processing="processing"></SubmitButton>
            <button type="button" class="btn btn-outline-pink mx-3" @click="hideCreateShiftModal"> Cancel </button>
          </b-modal>
        </div>

        <assignShiftModal ref="assignShift" :shift="assign_shift">
        </assignShiftModal>

        <!-- <div v-else-if="loading == false" class="row">
          <div
            class="card d-flex align-items-center justify-content-center p-4"
          >
            <span><i class="uil-exclamation-circle fs-1"></i></span>
            <p class="fs-5 fw-bold roboto">Empty Shifts</p>
          </div>
        </div> -->
      </div>
    </div>
  </Layout>
</template>
