<template>
  <div>
    <div class="row">
      <div class="col-md-12 mb-2">
        <div class="d-flex gap-2 justify-content-end">
          <div class="fc-toolbar-chunk">
            <div class="btn-group">
             <button @click="viewType='empWeek'"
                type="button"
                title="week view"
                aria-pressed="false"
                class="fc-timeGridWeek-button btn btn-primary"
              >
                Week</button
              ><button
                @click="viewType='empDay'"
                type="button"
                title="day view"
                aria-pressed="false"
                class="fc-timeGridDay-button btn btn-primary"
              >
                Day
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <emp-week-view v-if="viewType == 'empWeek'" @changeSlider="changeSlider" :weeks="weeks" :startDate="startDate" :endDate="endDate" :timelines="timelines"></emp-week-view>
    <emp-day-view v-else-if="viewType == 'empDay'" @changeSlider="changeSlider" :hours="hours" :dayDate="startDate" :timelines="timelines"></emp-day-view>
  </div>
</template>

<script>
import AlertWidget from "@/components/general/AlertWidget.vue";
import EmpDayView from "./empDayView.vue";
import EmpWeekView from './empWeekView.vue';
import moment from 'moment';
export default {
  name: "Employee",
  props: {
    area_id: {
      type: Number,
      default: null,
    },
    name : {
      type : String,
      default : null,
    },
    employee_id: {
      type: Number,
      default: null,
    },
    area_role_id : {
      type: Number,
      default: null,
    },
    availability : {
      type: String,
      default: null,
    }
  },
  components: {EmpDayView, EmpWeekView },
  data() {
    return {
      viewType:"empWeek",
      hours: [
        "12am",
        "01am",
        "02am",
        "03am",
        "04am",
        "05am",
        "06am",
        "07am",
        "08am",
        "09am",
        "10am",
        "11am",
        "12pm",
        "01pm",
        "02pm",
        "03pm",
        "04pm",
        "05pm",
        "06pm",
        "07pm",
        "08pm",
        "09pm",
        "10pm",
        "11pm",
      ],
      timelines: [],
      firstDate: null,
      lastDate: null,
      startDate: null,
      endDate: null,
      weeks: [],
      currentDate: new Date(),
      params: {
        shift_id: null,
        area_role_id: null,
        group_by: true,
        availability : null,
      },
      conditionalDay:6,
    };
  },

  watch: {
    area_id: {
      handler: function (newData) {
        this.params.area_id = newData;
        this.fetchTimelines("current");
      },
      // immediate: true,
    },
    name: {
      handler: function (newData) {
        this.params.name = newData;
        this.fetchTimelines("current");
      },
      // immediate: true,
    },
    employee_id: {
      handler: function (newData) {
        this.params.employee_id = newData;
        this.fetchTimelines("current");
      },
      // immediate: true,
    },
    area_role_id: {
      handler: function (newData) {
        this.params.area_role_id = newData;
        this.fetchTimelines("current");
      },
      // immediate: true,
    },
    availability: {
      handler : function(newData) {
        this.params.availability = newData;
        this.fetchTimelines("current");
      },
    },
    viewType: {
      handler: function (newData) {
        if(newData == "empWeek"){
          this.conditionalDay = 6;
          this.fetchTimelines("current");
        }
        if(newData == "empDay"){
          this.conditionalDay = 1;
          this.fetchTimelines("current")
        }
      },
    },
  },

  mounted() {
    this.fetchTimelines("current");
  },

  methods: {
    fetchTimelines(direction) {
      this.slider = direction;
      let dt = this.weekSlider(direction);
      this.params.from = this.customFormatDate(dt[0] , "DD-MM-YYYY");
      this.params.to = this.customFormatDate(dt[1] , "DD-MM-YYYY");
      this.$axios
        .get("/shift-timeline", { params: this.params })
        .then((response) => {
          let data = response.data.payload;
          this.timelines = data.data;
        })
        .catch((error) => {
          console.log(error, "error");
        })
        .finally(function () {});
    },

    getDailyDates(direction){
      // console.log("this is current date"+this.currentDate);
      if (direction == "next") {
        var next_date = moment(this.startDate).add(1, 'days');
        this.startDate = this.customFormatDate(next_date);
      return [next_date , next_date];
      }
      if(direction == 'previous'){
        var previous_date = moment(this.startDate).subtract(1 , 'days')
        this.startDate = this.customFormatDate(previous_date);
        return [previous_date , previous_date];
      }
      else{
         this.startDate = this.customFormatDate(new Date());
         return [this.startDate , this.startDate]  
      }
      

    },

    weekSlider(direction) {
      this.currentDate = new Date(this.currentDate);
      let dayNumber;
      if(this.viewType == "empWeek"){
         dayNumber = this.getDayNumberByName("Monday");
      }else{
        let dates = this.getDailyDates(direction);
        return dates;
        // dayNumber = this.currentDate.getDay();
        // console.log(dayNumber,this.currentDate.getDate());
      }
      var first1 =
        this.currentDate.getDate() - this.currentDate.getDay() + dayNumber;

      var last1 = first1 + this.conditionalDay; // last day is the first day + this.conditionalDay
      this.firstDate = new Date(this.currentDate.setDate(first1));
      this.lastDate = new Date(this.currentDate.setDate(last1));
      if (direction == "current") {
        this.currentDate = new Date();
      }
      if (direction == "next") {
        this.currentDate = this.lastDate;
      }
      if (direction == "previous") {
        this.currentDate = this.firstDate.setDate(this.firstDate.getDate() - this.conditionalDay);
      }
      var curr = new Date(this.currentDate); // get current date
      var first = curr.getDate() - curr.getDay() + dayNumber;

      var last = first + this.conditionalDay; // last day is the first day + this.conditionalDay
      var firstDate = new Date(curr.setDate(first));
      var lastdate = new Date(curr.setDate(last));

      this.startDate = this.customFormatDate(firstDate);
      this.endDate = this.customFormatDate(lastdate);
      this.weeks = [];
      this.weeks = this.getDates(firstDate, lastdate);

      return [firstDate, lastdate];
    },

    getDates(startDate, endDate) {
      //first the function and variable definitions
      let dates = [];
      const addDays = function (days) {
        var date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
      };
      //now our Sunday check
      let currDate = startDate;
      // if (currDate.getDay() > 0) {
      //   console.log("not a sunday...adjusting");
      //   currDate.setDate(currDate.getDate() - currDate.getDay());
      // }

      while (currDate <= endDate) {
        dates.push(this.customFormatDate(currDate, "ddd DD"));
        currDate = addDays.call(currDate, 1);
      }
      return dates;
    },
    changeSlider(data){
      this.timelines = {};
      this.fetchTimelines(data)
    }
  },
};
</script>